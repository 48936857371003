import React, { useState } from "react";
import {
  FormControlLabel,
  InputLabel,
  Slider,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateSignature } from "./../../util/db";
import CustomSwitch from "../CustomSwitch";
import GliideSignature from "./display";

const ANIMATION_IDS = ["anim1", "anim2", "anim3", "anim4"];

const useStyles = makeStyles((theme) => ({
  signatureContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AnimationControls = ({ signature }) => {
  // Animate
  const [animate, setAnimate] = useState(signature.animate === true);
  const handleAnimateChange = (event) => {
    console.log("event.target.value", event.target.checked);
    setAnimate(event.target.checked);
    updateSignature(signature.id, {
      animate: event.target.checked,
    });
  };

  // Speed
  const [speed, setSpeed] = useState(25 * (5 - signature.duration));
  const handleSpeedChange = (event, newValue) => {
    setSpeed(newValue);
  };
  const handleSpeedChangeCommitted = (event, newValue) => {
    updateSignature(signature.id, {
      duration: 5 - newValue / 25,
      rerender: true,
    });
  };

  // Delay
  const [delay, setDelay] = useState(signature.delay);
  const handleDelayChange = (event, newValue) => {
    setDelay(newValue);
  };
  const handleDelayChangeCommitted = (event, newValue) => {
    updateSignature(signature.id, { delay: newValue, rerender: true });
  };

  return (
    <Grid container={true} spacing={7}>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <FormControlLabel
            control={
              <CustomSwitch
                checked={animate}
                onChange={handleAnimateChange}
                name="animateSwitch"
              />
            }
            label={<Typography variant="h5">Animation</Typography>}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel id="animation-speed-label">Speed</InputLabel>
          <Typography>{Math.round(speed)}%</Typography>
        </Box>
        <Slider
          value={speed}
          onChange={handleSpeedChange}
          onChangeCommitted={handleSpeedChangeCommitted}
          aria-labelledby="speed-slider"
          step={1}
          min={0}
          max={100}
          valueLabelDisplay="auto"
        />
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel id="animation-delay-label">Loop Delay</InputLabel>
          <Typography>{delay} seconds</Typography>
        </Box>
        <Slider
          value={delay}
          onChange={handleDelayChange}
          onChangeCommitted={handleDelayChangeCommitted}
          aria-labelledby="delay-slider"
          step={0.25}
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

const GliideAnimationSelection = ({
  signature,
  animationId,
  selectedAnimation,
  disableCTA,
  onClick,
}) => {
  return (
    <GliideSignature
      signature={signature}
      animation={animationId}
      highlighted={selectedAnimation === animationId}
      handleClick={onClick}
      expandContainer={true}
      disableCTA={disableCTA}
    />
  );
};

const TabAnimation = ({ signature }) => {
  const classes = useStyles();
  // Animation Type
  const [animation, setAnimation] = useState(signature.animation);
  const handleAnimationChange = (newSelectedAnimation) => {
    console.log("New Animation: ", newSelectedAnimation);
    setAnimation(newSelectedAnimation);
    updateSignature(signature.id, {
      animation: newSelectedAnimation,
      rerender: true,
    });
  };

  return (
    <Grid container={true} spacing={7}>
      <Grid item xs={12}>
        <AnimationControls signature={signature} />
      </Grid>

      <Grid item container xs={12} spacing={3}>
        {ANIMATION_IDS.map((animationId) => (
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.signatureContainer}
            key={animationId}
          >
            <GliideAnimationSelection
              signature={signature}
              animationId={animationId}
              selectedAnimation={animation}
              disableCTA={true}
              onClick={() => handleAnimationChange(animationId)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TabAnimation;
