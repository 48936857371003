import React, { useEffect, useState } from "react";
import { Box, Card, TextField, IconButton, Button, Slider, Typography, InputLabel, Grid } from "@material-ui/core";
import { Height } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { updateSignature } from "./../../util/db";
import DeleteIcon from "@material-ui/icons/Delete";

const TextItemEditor = ({ index, item, onItemChange }) => {
  const [data, setData] = useState(item);
  useEffect(() => {
    setData(item);
  }, [item]);

  const onChange = (item) => {
    setData(item);
    onItemChange(index, item);
  };

  const showUrl = item.type === "link";

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" mb={showUrl ? 2 : 0}>
        {/* <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={data.type}
            onChange={(e) => onChange({ ...data, type: e.target.value })}
            label="Type"
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="link">Link</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </FormControl> */}
        <Box flexGrow={1} ml={2}>
          <TextField
            fullWidth
            label="Text"
            value={data.text}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => onChange({ ...data, text: e.target.value })}
          />
        </Box>
      </Box>
      {showUrl && (
        <Box display="flex" flexDirection="row">
          <TextField
            fullWidth
            label="URL"
            value={data.url}
            onChange={(e) => onChange({ ...data, url: e.target.value })}
          />
        </Box>
      )}
    </Box>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TabText = ({ signature }) => {
  // Items
  const [items, setItems] = useState();
  useEffect(() => {
    setItems(signature.info);
  }, [signature.info]);

  // Add New Item
  const canAddItem = signature && signature.info && signature.info.length < 4;
  const onAddItem = () => {
    const newItem = { type: "text", text: "" };
    const updatedItems = [...signature.info, newItem];
    setItems(updatedItems);
    updateSignature(signature.id, { info: updatedItems });
  };

  // Update Item
  const onItemChange = (index, item) => {
    const updatedItems = signature.info.map((oldItem, i) =>
      i === index ? item : oldItem
    );
    setItems(updatedItems);
    updateSignature(signature.id, { info: updatedItems });
  };

  // Delete Item
  const onItemDelete = (index) => {
    const updatedItems = signature.info.filter((item, i) => i !== index);
    setItems(updatedItems);
    updateSignature(signature.id, { info: updatedItems });
  };

  // Reordering
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      signature.info,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);
    updateSignature(signature.id, { info: reorderedItems });
  };

  // Line Height State
  const [lineHeight, setLineHeight] = useState(signature.line_height || 1.3);

  // Line Height Change Handler
  const handleLineHeightChange = (event, newValue) => {
    setLineHeight(newValue);
  };

  const handleLineHeightChangeCommitted = (event, newValue) => {
    updateSignature(signature.id, { line_height: newValue });
  };

  // Display
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Box width="50%" mb={0}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <InputLabel id="line-height-label">Line Height</InputLabel>
              <Typography>{lineHeight.toFixed(2)}</Typography>
            </Box>
            <Slider
              value={lineHeight}
              onChange={handleLineHeightChange}
              onChangeCommitted={handleLineHeightChangeCommitted}
              aria-labelledby="line-height-slider"
              step={0.05}
              min={1}
              max={2}
              valueLabelDisplay="auto"
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-info">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef} m={0}>
                {items &&
                  items.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box my={1}>
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            variant="outlined"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              flexDirection="row"
                            >
                              <Box
                                {...provided.dragHandleProps}
                                m={1}
                                display="flex"
                                justifyContent="center"
                              >
                                <Height />
                              </Box>
                              <Box flexGrow={1} my={2}>
                                <TextItemEditor
                                  index={index}
                                  item={item}
                                  onItemChange={onItemChange}
                                />
                              </Box>
                              <IconButton onClick={() => onItemDelete(index)}>
                                <DeleteIcon color="secondary" />
                              </IconButton>
                            </Box>
                          </Card>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item xs={12} >
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddItem}
            disabled={!canAddItem}
          >
            Add Row
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabText;
