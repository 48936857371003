import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms and Conditions</h1>
      <p>
        Please read these terms and conditions ("terms and conditions", "terms")
        carefully before using gliide.ai, app.gliide.ai, or any of the
        affiliated services operated by Gliide.
      </p>

      <h2>Conditions of Use</h2>
      <p>
        By using this website you certify that you have read and reviewed this
        Agreement and that you agree to comply with its terms. If you do not
        want to be bound by the terms of this Agreement, you are advised to stop
        using the website accordingly. Gliide only grants use and access of this
        website, its products, and its services to those who have accepted its
        terms.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        Before you continue using our website, we advise you to read our privacy
        policy [link to privacy policy] regarding our user data collection. It
        will help you better understand our practices.
      </p>

      <h2>Age Restriction</h2>
      <p>
        You must be at least 18 (eighteen) years of age before you can use this
        website. By using this website, you warrant that you are at least 18
        years of age and you may legally adhere to this Agreement. Gliide
        assumes no responsibility for liabilities related to age
        misrepresentation.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        You agree that all materials, products, and services provided on this
        website are the property of Gliide, its affiliates, directors, officers,
        employees, agents, suppliers, or licensors including all copyrights,
        trade secrets, trademarks, patents, and other intellectual property. You
        also agree that you will not reproduce or redistribute the Gliide’s
        intellectual property in any way, including electronic, digital, or new
        trademark registrations.
      </p>
      <p>
        You grant Gliide a royalty-free and non-exclusive license to display,
        use, copy, transmit, and broadcast the content you upload and publish.
        For issues regarding intellectual property claims, you should contact
        the company in order to come to an agreement.
      </p>

      <h2>User Accounts</h2>
      <p>
        As a user of this website, you may be asked to register with us and
        provide private information. You are responsible for ensuring the
        accuracy of this information, and you are responsible for maintaining
        the safety and security of your identifying information. You are also
        responsible for all activities that occur under your account or
        password.
      </p>
      <p>
        If you think there are any possible issues regarding the security of
        your account on the website, inform us immediately so we may address
        them accordingly.
      </p>
      <p>
        We reserve all rights to terminate accounts, edit or remove content, and
        cancel orders at our sole discretion.
      </p>

      <h2>Applicable Law</h2>
      <p>
        By using this website, you agree that the laws of the [your location],
        without regard to principles of conflict laws, will govern these terms
        and conditions, or any dispute of any sort that might come between
        Gliide and you, or its business partners and associates.
      </p>

      <h2>Third Party Costs</h2>
      <p>
        We reserve the right to adjust our pricing in the event of significant
        changes in our third-party costs.
      </p>

      <h2>Disputes</h2>
      <p>
        Any dispute related in any way to your use of this website or to
        products you purchase from us shall be arbitrated by state or federal
        court [your location] and you consent to exclusive jurisdiction and
        venue of such courts.
      </p>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify Gliide and its affiliates and hold Gliide
        harmless against legal claims and demands that may arise from your use
        or misuse of our services. We reserve the right to select our own legal
        counsel.
      </p>

      <h2>Limitation on Liability</h2>
      <p>
        Gliide is not liable for any damages that may occur to you as a result
        of your misuse of our website.
      </p>
      <p>
        Gliide reserves the right to edit, modify, and change this Agreement at
        any time. We shall let our users know of these changes through
        electronic mail. This Agreement is an understanding between Gliide and
        the user, and this supersedes and replaces all prior agreements
        regarding the use of this website.
      </p>
    </LongContent>
  );
}

export default LegalTerms;
