import React, { useEffect } from "react";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import Meta from "./../components/Meta";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GliideSignatureUI from "./../components/GliideSignature";
import { useSignature, createSignature, updateUser } from "../util/db";
import { useSignatureId } from "../util/signature";

function IndexPage(props) {
  const router = useRouter();
  const auth = useAuth();

  // Signature ID from User
  const authSignatureId = auth && auth.user && auth.user.signatureId;
  const authUserId = auth && auth.user && auth.user.id;
  const authenticating = auth.user === null;

  // Loaded Signature Data
  const { signatureId, setSignatureId } = useSignatureId();
  const { data: signature } = useSignature(signatureId);
  const signatureUserId = signature && signature.userId;
  const signatureStatus = signature && signature.status;
  const newSignature = signatureStatus === "pending";
  const hideLoadedSignature = newSignature || (signatureUserId && signatureUserId !== authUserId);
  const loadingText = newSignature ? "Creating Your Custom Signature..." : "Loading...";

  // Signature ID from Query
  const urlSignatureId = router && router.query && router.query.signature;

  // LinkedIn URL from Query
  const li_url = router && router.query && router.query.li;


  // Manage signature ID to load
  useEffect(() => {
    // New Signature Creation
    let isMounted = true;
    const createNewSignature = async (data) => {
      console.log("Creating New Signature:", data);
      try {
        const signatureRef = await createSignature(data);
        if (isMounted) {
          // Update state or perform other actions with the result
          console.log("New Signature Created:", signatureRef);
          console.log("New Signature ID:", signatureRef.id);
          setSignatureId(signatureRef.id);
          // update the user's signatureId
          if (data.userId) {
            await updateUser(data.userId, { signatureId: signatureRef.id });
          }
        }
      } catch (error) {
        if (isMounted) {
          // Handle error
          console.log("Error Creating New Signature:", error);
        }
      }
    };

    // console.log("----------------------------------------------------");
    // console.log("li_url:", li_url);
    // console.log("Authenticating:", authenticating);
    // console.log(auth);
    // console.log(auth.user);
    // console.log("...")

    // Wait for authentication to complete
    if (authenticating) {
      return;
    }

    // Determine which signature should be used
    let selectedSignatureId = authSignatureId || urlSignatureId || signatureId;
    if (signatureUserId && signatureUserId !== authUserId) {
      console.log("Signature is not from the user, setting to null");
      selectedSignatureId = null;
    }

    // console.log("User's Signature ID:", authSignatureId);
    // console.log("Router Signature ID:", urlSignatureId);
    // console.log("Stored Signature ID:", signatureId);
    // console.log("Loaded Signature ID:", signature && signature.id);
    // console.log("Selected Signature ID:", selectedSignatureId);
    // console.log("...");
    // console.log("Authentic User ID:", authUserId);
    // console.log("Signature User ID:", signatureUserId);
    // console.log("...");
    
    // Update the signature ID
    if (signatureId !== selectedSignatureId) {
      // Update the signature ID
      setSignatureId(selectedSignatureId);
    }

    if (selectedSignatureId) {
      // Clear the query params now that we've finished with them
      if (li_url || urlSignatureId) {
        console.log("Clearing query params:", li_url, urlSignatureId);
        router.replace(router.pathname, undefined, { shallow: true });
      }
    } else {
      if (li_url || authUserId) {
      // Create a signature or redirect to signin
        let signatureData = { "status": "pending" }
        if (li_url) signatureData["linkedin_url"] = li_url;
        if (authUserId) signatureData["userId"] = authUserId;
        createNewSignature(signatureData);
      } else {
        // Redirect to signin
        console.log("Redirecting to signin");
        router.push("/auth/signin");
      }
    }
  }, [
    router,
    authenticating,
    authUserId,
    signatureUserId,
    signatureId,
    authSignatureId,
    urlSignatureId,
    setSignatureId,
    li_url,
    // auth.user
  ]);

  return (
    <>
      <Meta title="Gliide.ai" />
      {/* Subscription Confirmation */}
      {router.query.paid && auth.user.planIsActive && (
        <Box mx="auto" mb={4} maxWidth={400}>
          <Alert severity="success">
            You are now subscribed to the {auth.user.planId} plan
            <span role="img" aria-label="party" style={{ marginLeft: "10px" }}>
              🥳
            </span>
          </Alert>
        </Box>
      )}
      {signature && !hideLoadedSignature ? (
        <GliideSignatureUI
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Signature Edit"
          subtitle=""
          signature={signature}
        />
      ) : (
          <Box
            ml="auto"
            mr="auto"
            mt={20}
            style={{
              width: "300px",
              height: "180px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
        <CircularProgress color="secondary" />
        <p></p>
        <Typography variant="body2">{loadingText}</Typography>
      </Box>
      )}
    </>
  );
}

export default IndexPage;
