import React from "react";
import AnimationDisplay from "./AnimationDisplay";
// import { useDarkMode } from "../../../util/theme";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CTAButton from "./CTAButton";
import clsx from "clsx";

const getImgSrc = (type) => {
  return `https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Ficons%2Foutline%2F${type}.png?alt=media`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "25px",
    border: "2px solid transparent",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
  },
  highlighted: {
    borderColor: theme.palette.primary.main,
    "&:hover": {
      borderColor: theme.palette.secondary.main,
    },
  },
  expanded: {
    height: "100%",
    width: "100%",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const GliideSignature = ({
  signature,
  render,
  highlighted,
  handleClick,
  animation,
  expandContainer,
  disableCTA,
}) => {
  // Styling
  const classes = useStyles();
  // const darkMode = useDarkMode();
  const textColor = "black !important";

  // Get line height from signature or use default
  const lineHeight = signature.line_height || 1.3;

  // Rendering Logic
  const showCTA = !disableCTA && signature.cta && signature.cta.text;
  const tableId = render ? "renderTable" : "signatureTable";
  const hasLinks = signature.links && signature.links.length > 0;

  // Rendering
  return (
    <Box
      className={clsx(classes.container, {
        [classes.highlighted]: highlighted,
        [classes.expanded]: expandContainer,
      })}
      onClick={handleClick}
      display="flex"
      justifyContent="center"
    >
      <table
        id={tableId}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          borderRadius: "25px",
          padding: "10px",
          backgroundColor: "white",
          fontSize: "20px",
          lineHeight: lineHeight,
          fontFamily: "Helvetica, serif",
        }}
      >
        <tbody>
          <tr>
            <td align="center" valign="top">
              <div>
                <AnimationDisplay
                  signature={signature}
                  render={render}
                  animation={animation}
                />
              </div>
            </td>
            <td align="left" valign="middle">
              <table style={{ marginTop: "0px", borderSpacing: 0 }}>
                <tbody>
                  <tr>
                    <td style={{ borderCollaps: "collapse" }}>
                      {/* Text Display */}
                      {signature.info &&
                        signature.info.length &&
                        signature.info.map((item, index) => (
                          <p
                            style={{
                              color: textColor,
                              fontWeight: index === 0 ? 800 : "normal",
                              minWidth: "175px",
                              margin: 0,
                              marginLeft: "4px",
                              marginTop: index === 0 ? "11px" : "0px",
                            }}
                            key={index}
                          >
                            {item.type === "link" ? (
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: textColor,
                                  fontStyle: "normal",
                                  textDecoration: "none",
                                }}
                              >
                                {item.text}
                              </a>
                            ) : item.type === "phone" ? (
                              <a
                                href={`tel:${item.text}`}
                                style={{
                                  color: textColor,
                                  fontStyle: "normal",
                                  textDecoration: "none",
                                }}
                              >
                                {item.text}
                              </a>
                            ) : item.type === "email" ? (
                              <a
                                href={`mailto:${item.text}`}
                                style={{
                                  color: textColor,
                                  fontStyle: "normal",
                                  textDecoration: "none",
                                }}
                              >
                                {item.text}
                              </a>
                            ) : (
                              item.text
                            )}
                          </p>
                        ))}
                      {/* Social Link Display */}
                      {hasLinks > 0 && (
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {signature.links.map((link, index) => (
                            <a
                              href={link.url}
                              key={index}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline-block",
                                width: "35px",
                                height: "35px",
                              }}
                            >
                              <img
                                src={getImgSrc(link.type)}
                                alt={link.type}
                                style={{ width: "35px", height: "35px" }}
                                // width={35}
                                // height={35}
                              />
                            </a>
                          ))}
                        </p>
                      )}
                      {/* CTA Display */}
                      {/* {showCTA && (
                        <CTAButton
                          text={signature.cta.text}
                          url={signature.cta.url}
                          textColor={signature.cta.textColor}
                          primaryColor={signature.cta.primaryColor || "#f50057"}
                          secondaryColor={signature.cta.secondaryColor || "#f50057"}
                          border={signature.cta.border}
                        ></CTAButton>
                      )} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          {showCTA && (
            <tr>
              <td align="center">
                <CTAButton
                  text={signature.cta.text}
                  url={signature.cta.url}
                  textColor={signature.cta.textColor}
                  primaryColor={signature.cta.primaryColor || "#f50057"}
                  secondaryColor={signature.cta.secondaryColor || "#f50057"}
                  border={signature.cta.border}
                  size={signature.cta.size}
                ></CTAButton>
              </td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
};

export default GliideSignature;
