import React from "react";

const CTAButton = ({
  text,
  url,
  textColor,
  primaryColor,
  secondaryColor,
  border,
  size = 50, // Default size if not provided
}) => {
  // Calculate size-dependent properties
  const fontSize = 14 + (size / 100) * 10; // 14px to 24px
  const paddingHorizontal = 10 + (size / 100) * 10; // 10px to 20px
  const paddingVertical = 5 + (size / 100) * 5; // 5px to 10px
  const borderRadius = 4 + (size / 100) * 4; // 4px to 8px

  return (
    <a
      href={url}
      style={{
        lineHeight: "1",
        color: textColor || "white",
        borderRadius: `${borderRadius}px`,
        display: "inline-block",
        border: border || "none",
        cursor: "pointer",
        backgroundImage: `linear-gradient(to right, ${primaryColor} 0%, ${secondaryColor} 51%, ${primaryColor} 100%)`,
        padding: `${paddingVertical}px ${paddingHorizontal}px`,
        textAlign: "center",
        backgroundSize: "200% auto",
        marginLeft: "4px",
        textDecoration: "none",
        fontWeight: "800",
        whiteSpace: "nowrap",
        fontSize: `${fontSize}px`,
      }}
    >
      {text}
    </a>
  );
};

export default CTAButton;
