// version 1.1.1
import anime from "animejs/lib/anime.es.js";

class GliideAnimation extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: "open" });

    const container = document.createElement("div");
    container.classList.add("render-area");
    container.innerHTML = `
      <style>
        .render-area {
          width: 285px;
          height: 150px;
          padding: 11px;
          background-color: ${this.getAttribute("background")};
        }
        .animation-area {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          position: relative;
        }
        .img-outer-container {
          width: 150px;
          height: 150px;
          position: absolute;
          border-radius: 75px;
        }
        .img-inner-container {
          width: 150px;
          height: 150px;
          border-radius: 75px;
          box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.5);
        }
        .img-inner-container img {
          width: 100%;
          height: 100%;
          border-radius: 75px;
        }
        #bottom {
          z-index: 1;
          scale: 0.001;
        }
        #top {
          z-index: 2;
          scale: 0.001;
        }
        .watermark {
          position: relative;
          top: -29px;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          font-size: 13px;
          display: flex;
          align-items: center;
          padding: 10px;
          z-index: 100;
          border-radius: 25px;
          visibility: ${this.getAttribute("watermark")};
        }
        .watermark img {
          width: 20px;
          height: 20px;
          padding-right: 10px;
          filter: brightness(0) invert(1);
        }
      </style>
      <div class="animation-area">
        <div class="img-outer-container" id="bottom">
          <div class="img-inner-container img-inner-container-bottom">
            <img class="image-bottom" src="${this.getAttribute(
              "secondary-image"
            )}" />
          </div>
        </div>
        <div class="img-outer-container" id="top">
          <div class="img-inner-container img-inner-container-top">
            <img id="image-top"  src="${this.getAttribute("primary-image")}" />
          </div>
        </div>
      </div>
      <div class="watermark">
        <img src="logo-watermark.png" />
        <span class="watermark-text">Gliide.ai Animated E-mail Signatures</span>
      </div>
    `;

    shadow.appendChild(container);

    this.duration = 2;
    this.delay = 2;
  }

  static get observedAttributes() {
    return [
      "primary-image",
      "secondary-image",
      "border-style",
      "watermark",
      "duration",
      "delay",
      "background",
      "autoplay",
      "playback",
      "animation",
    ];
  }

  getAnimationByType() {
    if (this.animationType === "anim2") {
      return anime
        .timeline({
          easing: "easeInOutQuad",
          duration: this.duration * 1000,
          autoplay: this.autoplay,
          loop: true,
        })
        .add({
          targets: this.shadowRoot.querySelector("#top"),
          duration: (this.duration * 1000) / 2,
          scale: 1000,
        })
        .add(
          {
            targets: this.shadowRoot.querySelector("#bottom"),
            duration: (this.duration * 1000) / 2,
            scale: 1000,
          },
          10
        )
        .add({
          targets: this.shadowRoot.querySelector("#top"),
          duration: (this.duration * 1000) / 2,
          translateX: -150,
        })
        .add({ duration: this.delay * 1000 });
    } else if (this.animationType === "anim3") {
      // anim3
      return anime
        .timeline({
          easing: "easeInOutQuad",
          duration: this.duration * 1000,
          autoplay: this.autoplay,
          loop: true,
        })
        .add({
          targets: this.shadowRoot.querySelector("#top"),
          duration: (this.duration * 1000) / 2,
          scale: 1000,
        })
        .add({ duration: this.delay * 1000 });
    } else if (this.animationType === "anim4") {
      // anim4
      return anime
        .timeline({
          easing: "easeInOutQuad",
          duration: this.duration * 1000,
          autoplay: this.autoplay,
          loop: true,
        })
        .add({
          targets: this.shadowRoot.querySelector("#bottom"),
          duration: (this.duration * 1000) / 2,
          scale: 1000,
        })
        .add({ duration: this.delay * 1000 });
    } else {
      // anim1
      return anime
        .timeline({
          easing: "easeInOutQuad",
          duration: this.duration * 1000,
          autoplay: this.autoplay,
          loop: true,
        })
        .add({
          targets: this.shadowRoot.querySelector("#top"),
          duration: (this.duration * 1000) / 2,
          scale: 1000,
        })
        .add(
          {
            targets: this.shadowRoot.querySelector("#bottom"),
            duration: (this.duration * 1000) / 2,
            scale: 1000,
          },
          10
        )
        .add({
          targets: this.shadowRoot.querySelector("#top"),
          duration: (this.duration * 1000) / 2,
          translateX: -135,
        })
        .add({ duration: this.delay * 1000 });
    }
  }

  setupStaticPropsForAnimation() {
    let inner = this.shadowRoot.querySelectorAll(".img-inner-container");
    let outer = this.shadowRoot.querySelectorAll(".img-outer-container");
    let images = this.shadowRoot.querySelectorAll(".img-inner-container img");
    let top = this.shadowRoot.querySelector("#bottom");
    let bottom = this.shadowRoot.querySelector("#bottom");
    let renderArea = this.shadowRoot.querySelector(".render-area");
    let watermarkSpan = this.shadowRoot.querySelector(".watermark-text");

    // Determine styling based on animation type
    let topVisibility = "visible";
    let bottomVisibility = "visible";
    let borderRadius = "75px";
    let imageSize = "150px";
    let renderWidth = "285px";
    let renderHeight = "150px";
    let watermarkText = "Gliide.ai Animated E-mail Signatures";

    if (this.animationType === "anim2") {
      borderRadius = "25px";
      imageSize = "130px";
      renderWidth = "280px";
      renderHeight = "130px";
    } else if (this.animationType === "anim3") {
      bottomVisibility = "hidden";
      renderWidth = "150px";
      watermarkText = "Gliide.ai";
    } else if (this.animationType === "anim4") {
      borderRadius = "25px";
      // imageSize = "140px";
      topVisibility = "hidden";
      renderWidth = "150px";
      watermarkText = "Gliide.ai";
    }

    // Apply styling
    watermarkSpan.textContent = watermarkText;
    renderArea.style.width = renderWidth;
    renderArea.style.height = renderHeight;
    top.style.visibility = topVisibility;
    bottom.style.visibility = bottomVisibility;
    inner.forEach((element) => {
      element.style.borderRadius = borderRadius;
      element.style.height = imageSize;
      element.style.width = imageSize;
    });
    outer.forEach((element) => {
      element.style.borderRadius = borderRadius;
      element.style.height = imageSize;
      element.style.width = imageSize;
    });
    images.forEach((element) => {
      element.style.borderRadius = borderRadius;
    });
  }

  setupAnimation() {
    // Clear Old Animation
    if (this.animation) {
      this.animation.pause();
      this.animation.seek(0);
      anime.remove("#top");
      anime.remove("#bottom");
      this.animation = null;
    }

    // Setup New Animation
    this.animation = this.getAnimationByType();
    if (this.playback > 0) {
      this.animation.pause();
      let seekTime = this.playback * this.duration * 1000;
      this.animation.seek(seekTime);
    }
  }

  connectedCallback() {
    this.setupAnimation();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "primary-image") {
      this.shadowRoot.querySelector("#top img").src = newValue;
    } else if (name === "secondary-image") {
      this.shadowRoot.querySelector("#bottom img").src = newValue;
    } else if (name === "border-style") {
      this.shadowRoot.querySelector(".render-area").style.border = newValue;
    } else if (name === "background") {
      this.shadowRoot.querySelector(".render-area").style.background = newValue;
    } else if (name === "watermark") {
      this.shadowRoot.querySelector(".watermark").style.visibility = newValue;
    } else if (name === "duration") {
      this.duration = Number(newValue);
      this.setupAnimation();
    } else if (name === "delay") {
      this.delay = Number(newValue);
      this.setupAnimation();
    } else if (name === "autoplay") {
      this.autoplay = newValue === "true";
      this.setupAnimation();
    } else if (name === "playback") {
      this.playback = Number(newValue);
      this.setupAnimation();
    } else if (name === "animation") {
      this.animationType = newValue;
      this.setupStaticPropsForAnimation();
      this.setupAnimation();
    }
  }

  disconnectedCallback() {
    if (this.animation) {
      this.animation.pause();
      this.animation.seek(0);
      anime.remove("#top");
      anime.remove("#bottom");
      this.animation = null;
    }
  }
}

customElements.define("gliide-animation", GliideAnimation);
export default GliideAnimation;
