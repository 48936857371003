import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px dashed ${theme.palette.primary.main}`,
    padding: "10px",
    margin: "10px 0px",
  },
  imageStyle: {
    padding: "0px",
    marginBottom: "0px",
    lineHeight: "1",
  },
  hover: {
    border: `2px dashed ${theme.palette.secondary.main}`,
  },
  textStyle: {},
}));

const ImageDropzone = ({ image, onImageDrop }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        onImageDrop(file);
      } else {
        console.log("Cannot upload more than 1 file.");
      }
    },
    [onImageDrop]
  );

  // Dropzone Management
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    maxFiles: 1,
  });

  // Display
  return (
    <div
      {...getRootProps()}
      className={clsx(classes.containerStyle, {
        [classes.hover]: isDragActive,
      })}
    >
      <div className={classes.imageStyle}>
        {/* Render image if available */}
        {image && (
          <img
            src={image}
            alt="Uploaded"
            style={{ width: "100px", height: "100px" }}
          />
        )}
      </div>
      <div className={classes.textStyle}>
        <input {...getInputProps()} />
        {/* {isDragActive ? (
          <p>Drop the image here...</p>
        ) : (
          <p>Drag 'n' drop new image here, or click to select image file.</p>
        )} */}
      </div>
    </div>
  );
};

export default ImageDropzone;
