import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import "./GliideAnimation";

const AnimationExportDisplay = ({ signature }) => {
  // const signatureUrl = getSignatureUrl(signature.id, signature.animate);
  const signatureUrl = signature.animate ? signature.rendered_url : signature.rendered_still_url;

  if (signature.rerender || signature.rendering_status !== "rendered") {
    return (
      <Box
        style={{
          width: "180px",
          height: "180px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
        <p></p>
        <Typography variant="body2">Rendering...</Typography>
      </Box>
    );
  }

  return <img src={signatureUrl} alt=""></img>;
};

export default AnimationExportDisplay;
