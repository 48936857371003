import React from "react";
import "./GliideAnimation";

const DEFAULT_PROFILE_URL =
  "https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Fprofile.png?alt=media&token=5aed82ac-8aaa-40c9-932d-a244703f038b";
const DEFAULT_LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Flogo.png?alt=media&token=31fd0066-00a0-4f81-a687-d317cee6ccbd";

const AnimationEditDisplay = ({ signature, animation }) => {
  return (
    <gliide-animation
      id="gliide-animation"
      primary-image={signature.images.primary || DEFAULT_PROFILE_URL}
      secondary-image={signature.images.secondary || DEFAULT_LOGO_URL}
      duration={signature.duration}
      delay={signature.delay}
      watermark="hidden"
      autoplay="true"
      animation={animation || signature.animation}
      playback={signature.animate ? 0 : 1}
    ></gliide-animation>
  );
};

window.renderer = {
  setAutoplay: (autoplay) => {
    const gliide = document.querySelector("#gliide-animation");
    gliide.setAttribute("autoplay", autoplay);
  },
  setPlayback: (playback) => {
    const gliide = document.querySelector("#gliide-animation");
    gliide.setAttribute("playback", playback);
  },
  setBackgroundColor: (color) => {
    const gliide = document.querySelector("#gliide-animation");
    if (color === "dark") {
      color = "#131313";
    } else if (color === "light") {
      color = "#fff";
    }
    gliide.setAttribute("background", color);
  },
  setWatermark: (visibility) => {
    const gliide = document.querySelector("#gliide-animation");
    if (visibility) {
      gliide.setAttribute("watermark", "visible");
    } else {
      gliide.setAttribute("watermark", "hidden");
    }
  },
};

export default AnimationEditDisplay;
