import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
// import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import { SignatureIdProvider } from "./../util/signature";

function App(props) {
  return (
      <QueryClientProvider>
        <ThemeProvider>
          <AuthProvider>
            <SignatureIdProvider>
              <Router>
                <>
                  <Navbar
                    color="default"
                    logo="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Fgliide_logo.svg?alt=media&token=24474e24-90f7-48d2-9d65-6e0427af45d5"
                    logoInverted="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Fgliide_logo.svg?alt=media&token=24474e24-90f7-48d2-9d65-6e0427af45d5"
                  />

                  <Switch>
                    <Route exact path="/" component={IndexPage} />

                    <Route exact path="/about" component={AboutPage} />

                    <Route exact path="/faq" component={FaqPage} />

                    <Route exact path="/contact" component={ContactPage} />

                    <Route exact path="/pricing" component={PricingPage} />

                    <Route exact path="/dashboard" component={IndexPage} />

                    <Route exact path="/auth/:type" component={AuthPage} />

                    <Route
                      exact
                      path="/settings/:section"
                      component={SettingsPage}
                    />

                    <Route exact path="/legal/:section" component={LegalPage} />

                    <Route
                      exact
                      path="/purchase/:plan"
                      component={PurchasePage}
                    />

                    <Route
                      exact
                      path="/firebase-action"
                      component={FirebaseActionPage}
                    />

                    <Route component={NotFoundPage} />
                  </Switch>

                  <Footer
                    bgColor="default"
                    size="medium"
                    bgImage=""
                    bgImageOpacity={1}
                    description="Stand out in every inbox."
                    copyright={`© ${new Date().getFullYear()} Gliide.ai`}
                    logo="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Fgliide_logo.svg?alt=media&token=24474e24-90f7-48d2-9d65-6e0427af45d5"
                    logoInverted="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Fgliide_logo.svg?alt=media&token=24474e24-90f7-48d2-9d65-6e0427af45d5"
                    sticky={true}
                  />
                </>
              </Router>
            </SignatureIdProvider>
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
  );
}

export default App;
