import React, { createContext, useState, useContext } from "react";
import { apiRequest } from "./util";

const SignatureIdContext = createContext();

const SignatureIdProvider = ({ children }) => {
  const [signatureId, setSignatureId] = useState(null);

  return (
    <SignatureIdContext.Provider value={{ signatureId, setSignatureId }}>
      {children}
    </SignatureIdContext.Provider>
  );
};

const useSignatureId = () => {
  const context = useContext(SignatureIdContext);
  if (context === undefined) {
    throw new Error('useSignatureId must be used within a SignatureIdProvider');
  }
  return context;
};

const callTriggerAnimationRender = (signatureId) => {
  console.log("signature.jsx:: triggerAnimationRender");
  apiRequest("trigger-animation-render", "POST", { signatureId });
};

export {
  SignatureIdContext,
  SignatureIdProvider,
  useSignatureId,
  callTriggerAnimationRender,
};
