import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
} from "@material-ui/core";
import { Height } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { updateSignature } from "./../../util/db";
import DeleteIcon from "@material-ui/icons/Delete";

const TextItemEditor = ({ index, item, onItemChange }) => {
  const [data, setData] = useState(item);
  useEffect(() => {
    setData(item);
  }, [item]);

  const onChange = (item) => {
    console.log(index, item);
    setData(item);
    onItemChange(index, item);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={data.type}
            onChange={(e) => onChange({ ...data, type: e.target.value })}
            label="Type"
            style={{ minWidth: "100px" }}
          >
            <MenuItem value="facebook">Facebook</MenuItem>
            <MenuItem value="github">Github</MenuItem>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="linkedin">LinkedIn</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            {/* <MenuItem value="messenger">Messenger</MenuItem> */}
            <MenuItem value="pinterest">Pinterest</MenuItem>
            <MenuItem value="tiktok">TikTok</MenuItem>
            <MenuItem value="twitterx">Twitter / X</MenuItem>
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
            <MenuItem value="youtube">YouTube</MenuItem>
            <MenuItem value="web">Website</MenuItem>
          </Select>
        </FormControl>
        <Box flexGrow={1} ml={2}>
          <TextField
            fullWidth
            label="URL"
            value={data.url}
            onChange={(e) => onChange({ ...data, url: e.target.value })}
          />
        </Box>
      </Box>
    </Box>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TabLinks = ({ signature }) => {
  // Items
  const [items, setItems] = useState();
  useEffect(() => {
    setItems(signature.links);
  }, [signature.links]);

  // Add New Item
  const canAddItem = signature && signature.links && signature.links.length < 5;
  const onAddItem = () => {
    const newItem = { type: "web", text: "" };
    const updatedItems = [...signature.links, newItem];
    setItems(updatedItems);
    updateSignature(signature.id, { links: updatedItems });
  };

  // Update Item
  const onItemChange = (index, item) => {
    const updatedItems = signature.links.map((oldItem, i) =>
      i === index ? item : oldItem
    );
    setItems(updatedItems);
    updateSignature(signature.id, { links: updatedItems });
  };

  // Delete Item
  const onItemDelete = (index) => {
    const updatedItems = signature.links.filter((item, i) => i !== index);
    setItems(updatedItems);
    updateSignature(signature.id, { links: updatedItems });
  };

  // Reordering
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      signature.links,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);
    updateSignature(signature.id, { links: reorderedItems });
  };

  // Display
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-links">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef} m={0}>
              {items &&
                items.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <Box my={1}>
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          variant="outlined"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                          >
                            <Box
                              {...provided.dragHandleProps}
                              m={1}
                              display="flex"
                              justifyContent="center"
                            >
                              <Height />
                            </Box>
                            <Box flexGrow={1} my={2}>
                              <TextItemEditor
                                index={index}
                                item={item}
                                onItemChange={onItemChange}
                              />
                            </Box>
                            <IconButton onClick={() => onItemDelete(index)}>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </Box>
                        </Card>
                      </Box>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onAddItem}
          disabled={!canAddItem}
        >
          Add Link
        </Button>
      </Box>
    </>
  );
};

export default TabLinks;
