import React from "react";
import AnimationEditDisplay from "./AnimationEditDisplay";
import AnimationExportDisplay from "./AnimationExportDisplay";

const AnimationDisplay = ({ signature, render, animation }) => {
  return render || signature.preview ? (
    <AnimationExportDisplay signature={signature} animation={animation} />
  ) : (
    <AnimationEditDisplay signature={signature} animation={animation} />
  );
};

export default AnimationDisplay;
